import React from "react"
import SEO from "../components/seo"

const ContactPage = () => (
  <>
    <SEO title="Contact Us" />
  </>
)

export default ContactPage
